<template>
  <div>
    <Sidebar 
      :active="active" 
      v-if="showSidebar" 
      @close="toggleSidebar" 
      @logout="logout" 
      @view="viewPrivateKey"
      @import="importKey"
      @generate="generateKey('show')"
    />
    <div id="change-pin">
        <div style="font-size:2.5rem;">
            <b-icon icon="arrow-bar-left" class="border rounded p-2" @click="toggleSidebar" style="cursor:pointer;"></b-icon>
        </div>
        <form class="change-pin-form mt-4" @submit.prevent="changePin">
            <h4 class="form-title mb-4">{{ $t('changePin.changePin') }}</h4>
            <div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
			<div class="alert alert-success" v-if="showSuccess"> {{ systemMsg }} </div>
            <div class="form-group">
                <label for="current-pin">{{ $t('changePin.currentPin') }}</label>
                <input type="text" id="current-pin" class="form-control" v-model="pin.current" maxlength="6" @keypress="isNumber($event)">
            </div>
            <div class="form-group">
                <label for="new-pin">{{ $t('changePin.newPin') }}</label>
                <input type="text" id="new-pin" class="form-control" v-model="pin.new1" maxlength="6" @keypress="isNumber($event)">
            </div>
            <div class="form-group">
                <label for="confirm-new-pin">{{ $t('changePin.confirmNewPin') }}</label>
                <input type="text" id="confirm-new-pin" class="form-control" v-model="pin.new2" maxlength="6" @keypress="isNumber($event)">
            </div>
            <button class="btn btn-main mt-4" type="submit">{{ $t('changePin.confirm') }}</button>
        </form>
    </div>
    <ConfirmLogout />
    <PrivateKey />
    <GeneratePrivateKey :state="generateState"/>
    <ImportPrivateKey />
  </div>
</template>

<script>
export default {
    components: {
        Sidebar:  () => import('../components/Sidebar.vue'),

        // Modals
        ConfirmLogout:  () => import('../components/modal/ConfirmLogout.vue'),
        PrivateKey:  () => import('../components/modal/privateKey/PINPrivateKey.vue'),
        ImportPrivateKey:  () => import('../components/modal/privateKey/ImportPrivateKey.vue'),
        GeneratePrivateKey:  () => import('../components/modal/privateKey/GeneratePrivateKey.vue'),
    },

    data() {
        return {
            showSidebar: false,
            pin: {
                current: "",
                new1: "",
                new2: "",
            },
            showError: false,
		    showSuccess: false,
            systemMsg: "",
            generateState: "",
            active: 'change-pin'
        }
    },

    computed: {
      init() {
          return this.$store.state.init;
      },
    },

    methods: {
        toggleSidebar() {
            this.showSidebar = !this.showSidebar
            this.active = 'change-pin'
        },

        logout: function(){
            this.$bvModal.show('logout-modal'); 
        },

        viewPrivateKey() {
            this.active="privatekey";
            console.log(this.active)
            console.log('..')
            this.init.viewPrivKey.state = 'pin';
            this.$bvModal.show('pin-key-modal'); 
        },

        generateKey(isShown){
            this.active="privatekey";
            this.generateState = isShown
            this.$bvModal.show('generate-key-modal');
            setTimeout(()=>{
                this.generateState = ""
            },5000);
        },

        importKey: function(){
            this.active="privatekey";
            this.$bvModal.show('import-key-modal'); 
        },

        changePin() {
			if(this.pin.current.length == 6 && this.pin.new1.length == 6 && this.pin.new2.length == 6) {
                const params = {
                    currentPin: this.pin.current,
                    newPin1: this.pin.new1,
                    newPin2: this.pin.new2
                    }

                    const data = Object.keys(params)
                    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
                    .join('&');
            

                    const options = {
                    method: 'POST',
                    headers: { 'content-type': 'application/x-www-form-urlencoded' },
                    data,
                    url: '/cx/changepin',
                    };
                    this.axios(options)
                        .then((response) => {
                            this.showError = false
                            this.showSuccess = true
                            this.systemMsg = this.i18n.t('changePin.changedPin')
                            this.pin.current = ""
                            this.pin.new1 = ""
                            this.pin.new2 = ""

                            setTimeout(()=>{
                                this.showSuccess = false
                            },6000);
                        })
                        .catch((error) => {
                            // Error code goes here
                            this.showError = true
                            this.systemMsg = error.response.data.msgText
                            if(this.systemMsg == "Entered new pins do not match") {
                                this.pin.new1 = ""
                                this.pin.new2 = ""
                            } else if (this.systemMsg == "Invalid current pin") {
                                this.pin.current = ""
                            }
                            setTimeout(()=>{
                            this.clearError()
                        },10000);
                    });
                } else if(this.pin.current.length != 6) {
                    this.pin.current = ""
                    this.showError = true
                    this.systemMsg = this.i18n.t('changePin.errors.currentPinDigits')
                    setTimeout(()=>{
                        this.clearError()
                    },10000);
                } else if(this.pin.new1.length != 6) {
                    this.pin.new1 = ""
                    this.showError = true
                    this.systemMsg = this.i18n.t('changePin.errors.newPinDigits')
                    setTimeout(()=>{
                        this.clearError()
                    },10000);
                } else if(this.pin.new2 != this.pin.new1) {
                    this.pin.new2 = ""
                    this.showError = true
                    this.systemMsg = this.i18n.t('changePin.errors.noMatchingPins')
                    setTimeout(()=>{
                        this.clearError()
                    },10000);
                }
        },

        clearError() {
			this.systemMsg = ""
			this.showError = false
		},

        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    }
}
</script>

<style scoped lang="scss">
    #change-pin {
        color:white;
        background-color:black; 
        padding:40px;
        height:100vh;

        .change-pin-form {
            width:500px;

            .form-title {
                font-family:OpenSans-SB;
                color:#c08000;
            }

            .form-control {
                background-color:#231f20;
                border:none;
                color:white;
            }
        }
    }
</style>